import http from "./http"
const newUrl = 'https://pay.gxb.cn/api'

// 获取用户余额信息 促销金，公司余额，个人余额等
export const $apiGetMoneyInfo = (data) => http({ method: "post", url: "/get/user/balance", data })
// 获取定金余额
export const $apiGetDeposit = (data) => http({ method: "get", url: "/order/user/money", params:data })

//充值  
export const $apiRecharge = (data) => http({ method: "post", url: newUrl+"/user/recharge", data })
//提现  
export const $apiWithdrawal = (data) => http({ method: "post", url: "/withdrawal", data })
// 获取用户余额信息
export const $apiGetBalance = (data) => http({ method: "post", url: newUrl+"/get/user/balance", data })

// 获取流水金额
export const $apiGetStreamSum = (data) => http({ method: "post", url: newUrl+"/get/user/stream/sum", data })
//转出 
export const $apiRollOut = (data) => http({ method: "post", url: newUrl+"/award/to/balance", data })
//保证金转出 
export const $apiMarginRollOut = (data) => http({ method: "post", url: newUrl+"/transfer/wallet", data })
//保证金赔付转出
export const $apiMarginPayRollOut = (data) => http({ method: "post", url: newUrl+"/margin/apply", data })
//vip奖励转出 
export const $apiVipRollOut = (data) => http({ method: "post", url: newUrl+"/vip/rewards/transfer/out/to/wallet", data })



